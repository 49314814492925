* {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    font-weight: lighter;
}

.noShow {
    display: none;
}

.banner {
    display: none;
}

header {
    display: flex;
    flex-flow: column;
    align-items: center;
}

h1 {
    font-size: 5rem;
    font-weight: lighter;
}

h2 {
    font-weight: lighter;
    font-size: 3rem;
}

body {
    background-color: var(--primaryBackColor);
    color: var(--textColor);
}

button, a {
    color: var(--textColor);
}

button {
    background-color: var(--secondaryBackColor);
    padding: 0.5rem;
    font-size: 1rem;
    cursor: pointer;
    letter-spacing: 1px;
    font-size: 1.5rem;
    border: none;
    border-bottom: 3px solid var(--navColor);
}

button:focus {
    outline: none;
}

.portrait {
    position: relative;
    box-shadow: 0.1rem 0.1rem 0.3rem var(--navColor);
    border-radius: 50%;
    width: 40%;
    max-width: 15rem;
}

ul {
    list-style: none;
    padding: 0;
}

li {
    display: inline;
}

a {
    text-decoration: none;
}

.smallLogo {
    min-width: 2rem;
    min-height: 2rem;
    max-width: 6rem;
    max-height: 3rem;
}

.largeLogo {
    min-width: 3rem;
    min-height: 3rem;
    max-width: 8rem;
    max-height: 5rem;
}

footer {
    position: fixed;
    bottom: 1.5rem;
    right: 1.5rem;
    width: 100%;
    display: flex;
    justify-content: flex-end;
}

.darkBack {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
}

@media only screen and (max-width: 600px) {
    h1 {
        font-size: 3rem;
    }
    
    header p {
        font-size: 2rem;
    }
    
    header span {
        font-size: 1.5rem;
    }

    .portrait {
        border-width: 3px;
    }

    .largeLogo {
        min-width: 2rem;
        min-height: 2rem;
        max-width: 6rem;
        max-height: 3rem;
    }

    .smallLogo {
        min-width: 1rem;
        min-height: 1rem;
        max-width: 4rem;
        max-height: 2rem;
    }
}

@media only screen and (max-width: 1000px) {
    .banner {
        display: flex;
        background-color: red;
        color: black;
        z-index: 4;
        position: fixed;
        top: 0;
        width: 100%;
        text-align: center;
        justify-content: space-evenly;
        font-weight: normal;
    }

    .banner button {
        justify-self: flex-end;
    }
}