.contact {
    position: fixed;
    top: 0;
    z-index: 3;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.contact .darkBack {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: -1;
}

.contact .window {
    background-color: var(--primaryBackColor);
    height: 20rem;
    width: 20rem;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: space-evenly;
}

.contact .window h2 {
    font-size: 2rem;
    margin: 0;
}

.contact a {
    font-weight: normal;
    border-bottom: 3px solid var(--navColor);
}

.contact a.social {
    border: none;
}

.contact img {
    max-width: 3rem;
}

