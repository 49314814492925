.apps {
    font-size: 1.5rem;
}

.apps .darkBack:hover {
    background-color: rgba(0, 0, 0, 0.5);
}

.apps article {
    margin: 1rem;
    padding: 2rem;
    display: grid;
    grid-template-areas: 
        "img info";
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 2rem;
    align-items: center;
    justify-items: center;
}

.apps article.even {
    grid-template-areas:
        "info img";
}

.apps article .app {
    width: 100%;
    height: 35rem;
    /* min-height: 35rem;
    max-height: 50rem; */
    grid-area: img;
    display: flex;
    justify-content: center;
    align-items: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-position-x: center;
    background-position-y: center;
}

.apps article .app h2 {
    border: 3px solid var(--popColor);
    padding: 1rem;
}

.apps article .app.genesis h2 {
    border: none;
}

.apps article .app.genesis .darkBack:hover {
    background-color: rgba(0, 0, 0, 0.7);
}

.apps article .info {
    display: flex;
    flex-flow: column;
}

.apps article .stack {
    align-self: flex-end;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    margin: 0;
}

.apps article .stack img {
    margin: 1rem;
}

.apps article .code {
    width: 100%;
    display: flex;
    justify-content: center;
}

.apps article section.code a {
    display: inline-flex;
    flex-flow: column;
    align-items: center;
    margin: 1rem;
}

.apps article .text {
    margin: 0;
}

.apps article .text a, .apps article .text span {
    font-weight: normal;
}

.apps article .text a {
    border-bottom: 3px solid var(--navColor);
}

.apps article .live, .apps article .code {
    align-self: flex-start;
    margin: 1rem
}

.apps a.app.iterate {
    background-image: url("https://russ-riser-portfolio.s3-us-west-1.amazonaws.com/purpleCard.JPG" );
}

.apps a.app.violestates {
    background-image: url("https://russ-riser-portfolio.s3-us-west-1.amazonaws.com/house.JPG" );
}

.apps a.app.genesis {
    background-image: url("https://russ-riser-portfolio.s3-us-west-1.amazonaws.com/genesis.jpg" );
}

.apps ul {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    padding-inline-start: 0;
}

@media only screen and (max-width: 950px) {
    .apps article, .apps article.even {
        grid-template-areas: 
                    "img" 
                    "info";
        grid-template-columns: 1fr;
    }

    .apps article .app {
        max-height: 25rem;
        min-height: 15rem;
    }

    .apps article .stack {
        margin: 1.5rem 0;
    }

    .apps article .stack img {
        margin: 0.5rem;
    }
}

@media only screen and (max-width: 750px) {
    .apps article .text, .apps article .code {
        font-size: 1.2rem;
        line-height: 1.7rem;
    }
}