.hobbies ul {
    display: grid;
    grid-template-areas: 
                "run rocket"
                "bike food";
}

.hobbies li {
    display: flex;
}

.hobbies .run {
    grid-area: run;
    justify-content: flex-end;
}

.hobbies .rocket {
    align-items: flex-end;
}

.hobbies .bike {
   justify-content: flex-end;
   align-items: flex-start; 
}

.hobbies img {
    max-width: 30rem;
    max-height: 30rem;
}

@media only screen and (max-width: 1050px) {
    .hobbies img {
        max-width: 20rem;
    }
}

@media only screen and (max-width: 750px) {
    .hobbies img {
        max-width: 15rem;
    }
}

@media only screen and (max-width: 550px) {
    .hobbies img {
        max-width: 10rem;
    }
}