nav {
    position: fixed;
    top: 0;
    z-index: 2;
    width: 100%;
    height: 100px;
    display: grid;
    grid-template-columns: 3rem 1fr;
    justify-items: center;
    align-items: center;
    background-color: var(--secondaryBackColor);
    box-shadow: 0 0 0.5rem var(--navColor);
}

nav ul {
    grid-area: 1 / 2 / span 1 / span 1;
    padding: 0;
    width: 90%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    margin: 0;
}

nav ul a {
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2rem;
    font-weight: lighter;
    padding: 0.5rem;
}

nav ul a:hover {
    font-size: 2.1rem;
    font-weight: normal;
}

nav ul a.selected {
    width: 35%;
    font-size: 3rem;
    border-bottom: 5px solid var(--navColor);
}

nav ul a.selected:hover {
    font-weight: lighter;
}

nav .toHome {
    grid-area: 1 / 1 / span 1 / span 1;
    justify-self: flex-end;
}

nav img {
    border: none;
    height: 4rem;
}

nav img:hover {
    height: 4.5rem;
}

#hireMe {
    position: fixed;
    top: 120px;
    right: 2rem;
    z-index: 3;
    border: none;
    border-bottom: 3px solid var(--popColor);
    box-shadow: none;
}

#hireMe:hover {
    font-weight: normal;
    border-bottom: 5px solid var(--popColor);
}

.content {
    margin-top: 150px;
}

@media only screen and (max-width: 920px) {
    nav ul a {
        font-size: 2rem;
    }
    
    nav ul a:hover {
        font-size: 2.1rem;
    }
    
    nav ul a.selected, nav ul a.selected:hover {
        font-size: 2.5rem;
        font-weight: lighter;
    }
}

@media only screen and (min-width: 550px) {
    nav ul a {
        width: 25%;
    }
}

@media only screen and (max-width: 780px) {
    nav {
        height: 60px;
        top: initial;
        bottom: 0;
    }

    #hireMe {
        top: 1rem;
        right: 1rem;
    }

    .content {
        margin-top: 0;
        margin-bottom: 5rem;
    }

    nav img {
        height: 2.5rem;
    }
    
    nav img:hover {
        height: 3.5rem;
    }

    nav ul a {
        font-size: 1.2rem;
        padding: 0;
        height: 2.5rem;
    }
    
    nav ul a:hover {
        font-size: 1.3rem;
    }
    
    nav ul a.selected, nav ul a.selected:hover {
        font-size: 1.5rem;
        font-weight: lighter;
        border-bottom: 3px solid var(--navColor);
    }
}