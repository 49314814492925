.tech {
    font-size: 1.5rem;
    font-weight: lighter;
}

.tech main {
    display: flex;
    flex-flow: column;
    align-items: center;
}

.tech section {
    width: 80%;
    margin-bottom: 2rem;
}

.tech ul {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    border: 5px solid var(--secondaryBackColor);
    padding: 1rem;
}

.tech li {
    display: inline-flex;
    flex-flow: column;
    align-items: center;
    justify-content: flex-end;
    padding: 1rem 2rem;
    box-sizing: border-box;
    margin: 0;
    text-align: center;
}

.tech li img {
    margin-bottom: 0.5rem;
}


@media only screen and (max-width: 600px) {
    .tech li {
        width: 50%;
        font-size: 1.2rem;
        padding: 1rem 0;
    }
}