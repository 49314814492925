.home {
    display: grid;
    justify-items: center;
    height: 100vh;
}

.home header {
    margin: 2rem 2rem 4rem 2rem;
    display: grid;
    grid-template-columns: 3fr 2fr 3fr;
    grid-template-areas: "name logo slogan";
    align-items: center;
    justify-items: center;
    width: 90%;
    align-self: flex-end;
}

.home .title {
    grid-area: name;
    justify-self: flex-end;
    margin: 0;
}

.home .subtitle {
    grid-area: slogan;
    font-weight: lighter;
    justify-self: flex-start;
}

.home .title, .home .subtitle {
    font-size: 4rem;
    margin: 1rem 0;
}

.home header img {
    grid-area: logo;
    height: 100%;
    max-height: 30vh;
}

.home main {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-evenly;
    padding: 1rem;
    height: 30vh;
    width: 100%;
    box-sizing: border-box;
    margin-top: 2rem;
    align-self: flex-start;
}

.home main a {
    width: 30%;
    height: 10vh;
    margin: 1rem 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--secondaryBackColor);
    font-size: 2.5rem;
}

.home main a:hover {
    font-weight: normal;
}

.home main a.apps:hover {
    border-bottom: 5px solid var(--tertiaryColor);
}

.home main a.tech:hover {
    border-bottom: 5px solid var(--secondaryColor);
}

.home main a.about:hover {
    border-bottom: 5px solid var(--primaryColor);
}


@media only screen and (max-width: 950px) {

    .home .title, .home .subtitle {
        font-size: 3.5rem
    }
}

@media only screen and (max-width: 850px) {

    .home .title, .home .subtitle {
        font-size: 3rem
    }

    .home header img {
        max-height: 25vh;
    }

    .home main a {
        font-size: 2rem;
    }
}


@media only screen and (max-width: 710px) {
    .home header {
        grid-template-columns: 1fr;
        grid-template-areas: 
                        "logo"
                        "name"
                        "slogan";
        margin: 1rem;
    }

    .home .title {
        justify-self: center;
        font-size: 3.5rem;
    }

    .home .subtitle {
        justify-self: center;
        font-size: 2rem;
    }

    .home .title, .home .subtitle {
        margin: 0;
    }

    .home header img {
        max-height: 25vh;
        margin-bottom: 1rem;
    }

    .home main {
        display: flex;
        flex-flow: column;
        align-items: center;
    }

    .home main a {
        width: 70%;
        margin: 0.5rem 0;
    }
}