.about {
    font-size: 1.2rem;
}

.about main {
    margin: 0 3rem;
}

.about blockquote {
    text-align: center;
}

.about blockquote span {
    font-style: italic;
}

.about .centeredArea {
    display: flex;
    flex-flow: column;
    align-items: center;
}

.about header p {
    text-align: center;
    line-height: 1.7rem;
    width: 70%;
    max-width: 60rem;
}

.about br {
    height: 2rem;
}

.about .nav {
    height: 6rem;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    background-color: var(--secondaryBackColor);
}

.about .nav a {
    width: 20%;
    padding: 1rem;
    text-align: center;
    font-weight: lighter;
    font-size: 1.3rem;
}

.about .nav a:hover {
    font-weight: normal;
    font-size: 1.4rem;
}

.about .nav a.selected {
    font-size: 2rem;
    font-weight: lighter;
    /* border-left: 5px solid var(--navColor);
    border-right: 5px solid var(--navColor); */
    border-bottom: 5px solid var(--navColor);
}

.about img {
    max-width: 15rem;
    max-height: 15rem;
}

@media only screen and (max-width: 900px) {
    .about header {
        margin-top: 2rem;
    }

    .about header blockquote, .about header blockquote span {
        font-size: 1rem;
    }

    .about header p {
        font-size: 1.2rem;
        width: 85%;
    }

    .about main {
        margin: 0 1rem;
    }

    .about main header {
        margin-top: 1rem;
    }

    .about .nav {
        height: 10rem;
        display: grid;
        grid-template: 1fr 1fr / 1fr 1fr;
        justify-items: center;
        background-color: var(--primaryBackColor);
    }

    .about .nav a {
        width: 95%;
        height: 90%;
        box-sizing: border-box;
        background-color: var(--secondaryBackColor);
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .about .nav a.selected {
        font-size: 1.7rem;
    }
}