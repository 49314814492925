section.experience {
    display: flex;
    flex-flow: column;
    align-items: center;
}

.experience article {
    margin: 3rem 2rem;
}

.experience article header {
    display: block;
    margin-bottom: 1rem;
}

.experience article h3 {
    margin: 0;
    font-weight: normal;
}

.experience article header span {
    font-style: italic;
}

.experience article main {
    display: flex;
    align-items: center;
}

.experience article ul {
    margin: 1rem;
}

.experience article li {
    display: list-item;
    list-style: square;
    margin: 1rem 2rem;
    line-height: 1.7rem;
}

.experience article li span {
    font-weight: bold;
}

@media only screen and (max-width: 1000px) {
    .experience article {
        margin: 1rem;
    }

    .experience article main {
        flex-flow: column;
        margin: 0;
    }

    .experience article main ul {
        margin: 0;
    }

    .experience article main img {
        margin: 1rem 0;
    }

    .experience article main li {
        margin: 2rem 1rem;
    }
}